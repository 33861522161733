.hero-img{
    width: 100%;
    background: rgba(0,0,0,0.5);
    height: 60vh;
    position: relative;

}

.heading{
    width: 100%;
    height: 100%;
    display:flex ;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img::before{
    content: "";
    background: url("https://images.unsplash.com/photo-1708481480603-b47ea65c80b6?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size:cover ;
    z-index: -1;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.hero-img h1{
    font-size: 2.4rem;
    
}

.hero-img p{
    font-size: 1.4rem;
    text-align: center;
    padding: 50px;
    
}

@media screen and (max-width:640px){
        hero-img h1{
            font-size: 2rem;
        }

        .hero-img p{
            font-size: 0.4rem;
            text-align: center;
            padding: 10px;
            
        }
}
