.nav-menu{
    display: flex;
}

.nav-menu li{
   padding: 0 1rem; 

}

.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}

.hamburger{
    display: none;
}

@media screen and (max-width:1040px){
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:100%;
        height:100vh;
        background-color: rgaba(0,0,0,0.9);
        position: absolute;
        top:0;
        left:-100%;
        z-index: -3;
        transition: 0.3s;
       
    }

    .nav-menu li{
        padding: 1rem 0;
    }

    .nav-menu li a{
        font-size: 2rem;
    }
    .nav-menu.active{
        left:0;
    }
    .hamburger{
        display: initial;
    }
}

.header-bg{
    background-color: rgba(0,0,0,0.85);
    transition: 0.5s;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    backdrop-filter: blur(5px); /* Apply blur effect */
    z-index: 9; /* Ensure it appears above other content */
    display: none; /* Initially hidden */
  }
  
  .nav-menu.active + .overlay {
    display: block; /* Show overlay when menu is active */
  }