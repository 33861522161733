.card-container{
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;

}

@media screen and (max-width:740px){
    .card-container{
        max-width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    
    }
}

.pricing{
    width: 100%;
    padding: 6rem 1rem;
    background-color: black;
}

.card{
    border: 1px solid #eee;
    text-align: center;
    color: #eee;
    padding: 1rem;
}

.card:hover{
    background-color:#141414 ;
}

.card h3{
    font-size: 1.4rem;
    padding: 1rem;
}

.card p{
    padding: 10px 0;
}

.bar{
    border-bottom: 1px solid #eee;
    width: 10%;
    margin: 1.5rem auto;
    display: block;
}

.btc{
    font-size: 4rem;
    font-family: 600;
    padding: 1rem 0;
}

.card .btn{
    display: block;
    width: 80%;
    margin: 2rem auto;

}